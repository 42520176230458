import { OrganizerType } from "../types/organizer";
import { StandhoudersType } from "../types/standhouder";

export const formatMail = (
  org: OrganizerType | StandhoudersType,
  addImages: boolean = false
): string => {
  const body = encodeURIComponent(
    `Hallo ${org.contactpersoon}, hier vind je de link naar jouw pagina: ${
      process.env.NEXT_PUBLIC_SITE_URL
    }${addImages ? "images/" : ""}${org.link}`
  );
  const subject = encodeURIComponent(
    `${
      (org as OrganizerType)?.nameBeurs || (org as StandhoudersType)?.name
    } - ${org.contactpersoon}`
  );
  return `mailto:${org.email}?subject=${subject}&body=${body}`;
};

export const formatMailToStandhouder = (
  org: OrganizerType,
  standhouder: StandhoudersType,
  template: string
) => {
  const body = encodeURIComponent(
    template
      .split("[NAME]")
      .join(standhouder.contactpersoon)
      .split("[LINK]")
      .join(process.env.NEXT_PUBLIC_SITE_URL + "images/" + standhouder.link)
      .split("[ORGANIZER]")
      .join(org.nameBeurs)
  );
  const subject = encodeURIComponent(
    `${org.nameBeurs} - ${standhouder.name} - ${standhouder.contactpersoon}`
  );
  return `mailto:${standhouder.email}?subject=${subject}&body=${body}`;
};
