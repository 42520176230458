/* eslint-disable @next/next/no-img-element */
import { NextApiRequest, NextApiResponse } from "next";
import Link from "next/link";
import { useRouter } from "next/router";
import { useEffect, useState } from "react";
import AddImageModal from "../../components/AddImageModal";
import { database } from "../../constants/database";
import useStore from "../../store/warehouse";
import { ImageType } from "../../types/image";
import { OrganizerType } from "../../types/organizer";
import { SizeType } from "../../types/size";
import { StandhoudersType } from "../../types/standhouder";
import { db } from "../../utils/database";
import {
  FaTrash,
  FaPlus,
  FaInfoCircle,
  FaEnvelope,
  FaQuestionCircle,
  FaBackward,
  FaPen,
  FaPlusCircle,
  FaArrowCircleRight,
  FaArrowLeft,
  FaCaretDown,
  FaCaretUp,
  FaHeart,
} from "react-icons/fa";
import { formatMailToStandhouder } from "../../utils/mail";
import EditStandhouderModal from "../../components/EditStandhouderModal";
import LayoutOrganizer from "../../components/LayoutOrganizer";
import StandhouderId from "../api/loadStandhouderData/[standhouderId]";
import { getNiceDate } from "../../utils/date";
import React from "react";

const OrganizationPage = ({}: {}) => {
  const {
    loading,
    organizers,
    standhouders,
    sizes,
    isAdmin,
    images,
    setLoading,
    loadDataOrganizer,
    setSentMail,
    deleteImage,
  } = useStore();
  const router = useRouter();
  const [reminders, setReminders] = useState<StandhoudersType[]>([]);
  const [editTemplate, setEditTemplate] = useState(false);
  const [template, setTemplate] = useState("");
  const [showHelp, setShowHelp] = useState(false);
  const [search, setSearch] = useState("");
  const [showDetails, setShowDetails] = useState<
    StandhoudersType | undefined
  >();

  const [sortColumn, setSortColumn] = useState("");
  const [sortDesc, setSortDesc] = useState(1);

  const clickColumn = (col: string) => {
    if (col === sortColumn) {
      setSortDesc(sortDesc * -1);
    } else {
      setSortColumn(col);
      setSortDesc(1);
    }
  };
  const [editStandhouder, setEditStandhouder] =
    useState<StandhoudersType | null>();
  const organizerId = router.query.organizerId as string;
  const [standHouders, setStandHouders] =
    useState<StandhoudersType[]>(standhouders);

  useEffect(() => {
    if (organizerId) {
      setLoading(true);
      loadDataOrganizer(organizerId);
    }
  }, [organizerId]);

  useEffect(() => {
    if (organizerId && organizers.length) {
      const myData = organizers.find((org) => org.link === organizerId);
      setTemplate(
        myData?.emailBody ||
          `Hallo [NAME],

hier vind je de link naar jouw pagina: [LINK]

Groeten,
[ORGANIZER]`
      );
    }
  }, [organizerId, organizers]);

  useEffect(() => {
    if (standhouders.length) {
      setStandHouders(
        standhouders.filter((s) => s.organizerId === organizerId)
      );
    }
  }, [standhouders]);

  const myData = organizers.find((org) => org.link === organizerId);
  const [selectedStandhouder, setSelectedStandhouder] =
    useState<StandhoudersType>();

  if (!myData) return null;
  return (
    <LayoutOrganizer>
      <div className="page noprint">
        <div className="container">
          {isAdmin && (
            <Link href="/">
              <a className="adminBackButton">
                <FaArrowLeft></FaArrowLeft>Home
              </a>
            </Link>
          )}
          <header className="spaceBetween mb1">
            <h1>
              {myData.logo ? (
                <img
                  className="logo"
                  height={50}
                  src={myData.logo}
                  alt={myData.nameBeurs}
                />
              ) : (
                myData.nameBeurs
              )}
            </h1>
            <div>
              <button className="helpButton" onClick={() => setShowHelp(true)}>
                <FaQuestionCircle></FaQuestionCircle>
              </button>
              <button
                onClick={() => setEditTemplate(true)}
                className="link secondary-button mr1"
              >
                edit mail template
              </button>
              <Link href={"/" + organizerId + "/bulk"}>
                <a className="link secondary-button mr1">Add Bulk</a>
              </Link>
              <Link href={"/" + organizerId + "/add"}>
                <a className="link primary-button">Add</a>
              </Link>
            </div>
          </header>

          <div className="mb1">
            <input
              type="search"
              placeholder="Search"
              value={search}
              onChange={(e) => setSearch(e.target.value)}
            ></input>
          </div>
          <div className="date">{getNiceDate(myData.date)}</div>
          <p className="reminder">
            {
              images.filter(
                (i) =>
                  i.organizerId === organizerId &&
                  standhouders.find((sh) => sh.link === i.standhouderId)
                    ?.active &&
                  i.preview
              ).length
            }
            /
            {
              images.filter(
                (i) =>
                  i.organizerId === organizerId &&
                  standhouders.find((sh) => sh.link === i.standhouderId)?.active
              ).length
            }{" "}
            images have been uploaded.
            <br /> Click{" "}
            <button
              onClick={async () => {
                const arrayOfStandhouderIds = images
                  .filter(
                    (i) =>
                      i.organizerId === organizerId &&
                      standhouders.find((sh) => sh.link === i.standhouderId)
                        ?.active &&
                      !i.preview
                  )
                  .map((i) => i.standhouderId)
                  .filter(function (item, pos, self) {
                    return self.indexOf(item) == pos;
                  });
                const arrayOfStandhouders = standhouders.filter((sh) =>
                  arrayOfStandhouderIds.includes(sh.link)
                );
                setReminders(arrayOfStandhouders);
              }}
            >
              here
            </button>{" "}
            to send a reminder email.
          </p>
          {/* 
<div>saved filters
  <div className="filters">

  </div>
  <button>
    <FaHeart></FaHeart>
  </button>
</div> */}
          <table className="table">
            <thead>
              <tr>
                <th onClick={() => clickColumn("standNummer")}>
                  Standnummer{" "}
                  {sortColumn === "standNummer" && sortDesc == -1 && (
                    <FaCaretDown></FaCaretDown>
                  )}
                  {sortColumn === "standNummer" && sortDesc === 1 && (
                    <FaCaretUp></FaCaretUp>
                  )}
                </th>
                <th onClick={() => clickColumn("name")}>
                  Name{" "}
                  {sortColumn === "name" && sortDesc == -1 && (
                    <FaCaretDown></FaCaretDown>
                  )}
                  {sortColumn === "name" && sortDesc === 1 && (
                    <FaCaretUp></FaCaretUp>
                  )}
                </th>
                <th onClick={() => clickColumn("contactpersoon")}>
                  Contactpersoon{" "}
                  {sortColumn === "contactpersoon" && sortDesc == -1 && (
                    <FaCaretDown></FaCaretDown>
                  )}
                  {sortColumn === "contactpersoon" && sortDesc === 1 && (
                    <FaCaretUp></FaCaretUp>
                  )}
                </th>
                <th onClick={() => clickColumn("email")}>
                  Email{" "}
                  {sortColumn === "email" && sortDesc == -1 && (
                    <FaCaretDown></FaCaretDown>
                  )}
                  {sortColumn === "email" && sortDesc === 1 && (
                    <FaCaretUp></FaCaretUp>
                  )}
                </th>
                <th>Images</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {standHouders
                .filter(
                  (sh) =>
                    sh.name?.toLowerCase().includes(search?.toLowerCase()) ||
                    sh.email?.toLowerCase().includes(search?.toLowerCase()) ||
                    sh.contactpersoon
                      ?.toLowerCase()
                      .includes(search?.toLowerCase())
                )
                .sort((a, b) =>
                  //@ts-ignore
                  a[sortColumn] < b[sortColumn] ? -1 * sortDesc : 1 * sortDesc
                )
                .map((standhouder) => {
                  return (
                    <tr key={standhouder._id}>
                      <td>{standhouder.standNummer}</td>
                      <td>
                        <Link href={"/images/" + standhouder.link}>
                          <a>{standhouder.name}</a>
                        </Link>
                      </td>
                      <td>{standhouder.contactpersoon}</td>
                      <td>
                        <a
                          target="_blank"
                          rel="noreferrer"
                          href={formatMailToStandhouder(
                            myData,
                            standhouder,
                            template
                          )}
                          className="sendMail"
                          onClick={() => {
                            setSentMail(standhouder.link, true);
                          }}
                        >
                          {standhouder.email}
                          <FaEnvelope
                            style={{
                              color: standhouder.emailSent
                                ? "green"
                                : "var(--light-grey)",
                            }}
                          ></FaEnvelope>
                        </a>
                      </td>
                      <td>
                        {
                          images.filter(
                            (img) =>
                              img.standhouderId === standhouder.link &&
                              img.preview
                          ).length
                        }{" "}
                        /{" "}
                        {
                          images.filter(
                            (img) => img.standhouderId === standhouder.link
                          ).length
                          // .map((img, index) => {
                          //   return (
                          //     <li key={index}>
                          //       <div className="deleteWrap">
                          //         {img.name}
                          //         {img.preview && (
                          //           <img
                          //             alt={"preview voor: " + img.fileName}
                          //             src={img.preview}
                          //           />
                          //         )}

                          //         <button
                          //           className="delete"
                          //           onClick={() => {
                          //             fetch(`/api/images`, {
                          //               method: "DELETE",
                          //               headers: {
                          //                 "Content-Type": "application/json",
                          //               },
                          //               body: JSON.stringify({
                          //                 id: img._id,
                          //               }),
                          //             });
                          //           }}
                          //         >
                          //           <FaTrash></FaTrash>
                          //         </button>
                          //       </div>
                          //     </li>
                          //   );
                          // })
                        }
                        <button
                          onClick={() => setShowDetails(standhouder)}
                          className="tableActionButton"
                        >
                          <FaInfoCircle></FaInfoCircle>
                        </button>
                      </td>
                      <td>
                        <button
                          onClick={() => setSelectedStandhouder(standhouder)}
                          title="Add image"
                        >
                          <FaPlusCircle></FaPlusCircle>
                        </button>
                        <Link href={"/images/" + standhouder.link}>
                          <a>
                            <FaArrowCircleRight></FaArrowCircleRight>
                          </a>
                        </Link>
                        <button
                          className="delete"
                          onClick={() => {
                            setEditStandhouder(standhouder);
                          }}
                        >
                          <FaPen />
                        </button>

                        <button
                          className="delete"
                          onClick={() => {
                            setStandHouders(
                              standHouders.filter(
                                (s) => s.link !== standhouder.link
                              )
                            );
                            fetch(`/api/standhouders`, {
                              method: "DELETE",
                              headers: {
                                "Content-Type": "application/json",
                              },
                              body: JSON.stringify({
                                standhouderId: standhouder.link,
                              }),
                            });
                          }}
                        >
                          <FaTrash />
                        </button>
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </table>

          <AddImageModal
            sizes={sizes}
            organizer={myData}
            organizerId={organizerId}
            standhouder={selectedStandhouder}
            setSelectedStandhouder={setSelectedStandhouder}
          ></AddImageModal>
          {editStandhouder && (
            <EditStandhouderModal
              standhouder={editStandhouder}
              hideModal={() => setEditStandhouder(null)}
            />
          )}
          {editTemplate && (
            <div className="modal" onClick={() => setEditTemplate(false)}>
              <div className="inner" onClick={(e) => e.stopPropagation()}>
                <div className="mb2">
                  <h2>Mail template</h2>

                  <textarea
                    name="template"
                    id="template"
                    cols={30}
                    rows={10}
                    value={template}
                    onChange={(e) => setTemplate(e.target.value)}
                  ></textarea>
                </div>

                <div className="spaceBetween gap2">
                  <button
                    type="button"
                    className="secondary-button"
                    onClick={() => setEditTemplate(false)}
                  >
                    cancel
                  </button>

                  <button
                    onClick={() => {
                      fetch(`/api/organizers`, {
                        method: "PUT",
                        headers: {
                          "Content-Type": "application/json",
                        },
                        body: JSON.stringify({
                          _id: organizerId,
                          template,
                        }),
                      });
                      setEditTemplate(false);
                    }}
                    className="primary-button"
                  >
                    Save
                  </button>
                </div>
              </div>
            </div>
          )}
          {showDetails && (
            <div className="modal" onClick={() => setShowDetails(undefined)}>
              <div className="inner" onClick={(e) => e.stopPropagation()}>
                <h2 className="mb1">
                  {showDetails.standNummer} - {showDetails.name}
                </h2>
                <ul className="imageList mb1">
                  {images
                    .filter((img) => img.standhouderId === showDetails.link)
                    .map((img, index) => {
                      return (
                        <li key={index}>
                          {img.preview ? (
                            <img
                              height={30}
                              alt={"preview voor: " + img.fileName}
                              src={img.preview}
                            />
                          ) : (
                            <span className="emptyImage">?</span>
                          )}
                          {img.name}

                          <button
                            className="tableActionButton"
                            onClick={() => {
                              setLoading(true);
                              deleteImage(img);
                            }}
                          >
                            <FaTrash></FaTrash>
                          </button>
                        </li>
                      );
                    })}
                </ul>
                <div className="spaceBetween gap2">
                  <button
                    type="button"
                    className="secondary-button"
                    onClick={() => setShowDetails(undefined)}
                  >
                    Close
                  </button>
                </div>
              </div>
            </div>
          )}
          {showHelp && (
            <div className="modal" onClick={() => setShowHelp(false)}>
              <img
                className="helpImage"
                src="/howto.png"
                alt="image with information what all buttons do"
              />
            </div>
          )}

          {loading && (
            <div id="loader">
              Loading{" "}
              <div className="lds-ellipsis">
                <div></div>
                <div></div>
                <div></div>
                <div></div>
              </div>
            </div>
          )}

          {!!reminders.length && (
            <div className="modal" onClick={() => setReminders([])}>
              <div className="inner" onClick={(e) => e.stopPropagation()}>
                <h2 className="mb1">Send reminder mail</h2>

                <p>Following exhibitors have not yet uploaded their images:</p>
                <ul className="listButtons mb1">
                  {reminders.map((reminder, index) => {
                    return (
                      <li key={index}>
                        {reminder.standNummer} - {reminder.name}
                      </li>
                    );
                  })}
                </ul>
                <div className="spaceBetween gap2">
                  <button
                    type="button"
                    className="secondary-button"
                    onClick={() => setReminders([])}
                  >
                    Close
                  </button>

                  <button
                    onClick={(e) => {
                      //@ts-ignore
                      e.target.disabled = true;
                      const response = fetch("/api/mail", {
                        method: "POST",
                        headers: {
                          "Content-Type": "application/json",
                        },
                        body: JSON.stringify({
                          organizer: myData,
                          date: getNiceDate(myData.date),
                          standhouders: reminders,
                        }),
                      });
                      setReminders([]);
                    }}
                    className="primary-button"
                  >
                    Send
                  </button>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>

      <div className="page print">
        <div className="container">
          <h1>
            {myData.logo ? (
              <img
                className="logo"
                height={50}
                src={myData.logo}
                alt={myData.nameBeurs}
              />
            ) : (
              myData.nameBeurs
            )}
          </h1>

          <table className="table printtable">
            <thead>
              <tr>
                <th>Standnummer</th>
                <th>Name</th>
                <th>Contactpersoon</th>
                <th>Email</th>
              </tr>
            </thead>
            <tbody>
              {standHouders.map((standhouder) => {
                return (
                  <React.Fragment key={standhouder._id}>
                    <tr>
                      <td>{standhouder.standNummer}</td>
                      <td>
                        <Link href={"/images/" + standhouder.link}>
                          <a>{standhouder.name}</a>
                        </Link>
                      </td>
                      <td>{standhouder.contactpersoon}</td>

                      <td>
                        <a
                          target="_blank"
                          rel="noreferrer"
                          href={formatMailToStandhouder(
                            myData,
                            standhouder,
                            template
                          )}
                          className="sendMail"
                          onClick={() => {
                            setSentMail(standhouder.link, true);
                          }}
                        >
                          {standhouder.email}
                          <FaEnvelope
                            style={{
                              color: standhouder.emailSent
                                ? "green"
                                : "var(--light-grey)",
                            }}
                          ></FaEnvelope>
                        </a>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        {images
                          .filter(
                            (img) => img.standhouderId === standhouder.link
                          )
                          .map((img, index) => {
                            return (
                              <div key={index}>
                                {img.name}
                                {img.preview && (
                                  <img
                                    alt={"preview voor: " + img.fileName}
                                    src={img.preview}
                                  />
                                )}
                              </div>
                            );
                          })}
                      </td>
                    </tr>
                  </React.Fragment>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
    </LayoutOrganizer>
  );
};

export default OrganizationPage;
