import { useState } from "react";
import useStore from "../store/warehouse";
import { ImageType } from "../types/image";
import { OrganizerType } from "../types/organizer";
import { SizeType } from "../types/size";
import { StandhoudersType } from "../types/standhouder";

const AddImageModal = ({
  standhouder,
  sizes,
  setSelectedStandhouder,
  // addImage,
  organizer,
  organizerId,
}: {
  standhouder: StandhoudersType | undefined;
  organizerId: string;
  organizer: OrganizerType;
  setSelectedStandhouder: (standhouderId: StandhoudersType | undefined) => void;
  sizes: SizeType[];
  // addImage: (image: ImageType) => void;
}) => {
  const { setLoading, addImage } = useStore();
  const [size, setSize] = useState<SizeType | undefined>();
  const [amount, setAmount] = useState<number>(1);

  if (!standhouder) return null;
  return (
    <div
      className="modal"
      onClick={(e) => {
        e.stopPropagation();
        setSelectedStandhouder(undefined);
      }}
    >
      <div className="inner" onClick={(e) => e.stopPropagation()}>
        <div className="mb2">
          <h1>Add image</h1>
        </div>
        <form
          className="w100"
          onSubmit={async (e) => {
            e.preventDefault();
            setLoading(true);
            const image: ImageType = {
              standhouderId: standhouder.link,
              organizerId: organizerId,
              fileName: "",
              preview: "",
              active: true,
              size: size!.width + "x" + size!.height,
              numberOfDays: size!.daysBeforeToOrder,
              supplier: size!.supplier,
              height: size!.height,
              width: size!.width,
              fiche: size!.fiche,
              name: size!.name,
              keepFrame: false,
            };

            for (let index = 0; index < amount; index++) {
              const newImageResponse = await fetch("/api/images", {
                method: "POST",
                headers: {
                  "Content-Type": "application/json",
                },
                body: JSON.stringify({ image, amount }),
              });
              const newImage = await newImageResponse.json();

              addImage(newImage.image);
            }
            setSize(undefined);
            setAmount(1);
            setLoading(false);
            setSelectedStandhouder(undefined);
          }}
        >
          {/* <div className="form-input">
            <label htmlFor="name">Naam</label>
            <input
              required
              value={name}
              onChange={(e) => setName(e.target.value)}
              type="text"
              name="name"
              id="name"
            />
          </div> */}
          <div className="form-input ">
            <label htmlFor="size">Size</label>
            <select
              id="size"
              name="size"
              required
              value={size?._id}
              onChange={(e) =>
                setSize(sizes.find((s) => s._id! === e.target.value))
              }
            >
              <option value={undefined}>Choose a size</option>
              {sizes
                .filter((x) => (organizer.sizes || []).includes(x._id!))
                .map((size, index) => (
                  <option key={index} value={size._id}>
                    {size.name} ({size.width}x{size.height})
                  </option>
                ))}
            </select>
          </div>

          <div className="form-input mb2">
            <label htmlFor="amount">Amount</label>
            <input
              type="number"
              name="amount"
              id="amount"
              value={amount}
              onChange={(e) => setAmount(e.target.valueAsNumber)}
            />
          </div>
          <div className="spaceBetween gap2">
            <button
              type="button"
              className="secondary-button"
              onClick={() => setSelectedStandhouder(undefined)}
            >
              cancel
            </button>

            <button className="primary-button" disabled={!size}>
              Add
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default AddImageModal;
